import { Tabs } from 'antd';
import { PureComponent } from 'react';

class TabsEnhanced extends PureComponent {

    constructor(props){
        super(props);
        this.onKeyUp = this.onKeyUp.bind(this);

        const { activeKey, defaultActiveKey } = props;
        
        this.state = {
            activeKey: activeKey ?? defaultActiveKey
        }
    }

    onKeyUp(event)
    {
        if (event.ctrlKey === true && event.altKey === true) 
        {
            if (["ArrowLeft", "ArrowRight"].includes(event.key))
            {
                const { activeKey } = this.state;
                const { children, onChange } = this.props;                
                const filteredChildren = Array.isArray(children) ? children.filter(child => child?.type?.name === "TabPane") : [];

                if (filteredChildren.length > 1)
                {
                    // default -1, otherwise get frist matched item index
                    let shortCutKeyIndex = activeKey.length > 0 ? filteredChildren.findIndex(child => activeKey.includes(child.key)) ?? -1 : -1;
                    shortCutKeyIndex = (shortCutKeyIndex + (event.key === "ArrowLeft" ? (filteredChildren.length - 1) : 1)) % filteredChildren.length;
                    let newActiveKey = (shortCutKeyIndex > -1 && shortCutKeyIndex < filteredChildren.length) ? filteredChildren[shortCutKeyIndex].key : (filteredChildren.length > 0 ? filteredChildren[0].key : "")
    
                    this.setState({ activeKey: newActiveKey });
                    onChange && onChange(newActiveKey);
                }
            }
        }
    }

    componentDidUpdate(prevProps)
    {
        const { activeKey, enableShortCutKey } = this.props;
        const { activeKey: prevActiveKey, enableShortCutKey: prevEnableShortCutKey } = prevProps;
        
        if (activeKey !== prevActiveKey && activeKey !== this.state.activeKey)
        {
            this.setState({ activeKey });
        }
        if (enableShortCutKey !== prevEnableShortCutKey)
        {
            if (enableShortCutKey)
            {
                document.addEventListener("keyup", this.onKeyUp, false);
            }
            else
            {
                document.removeEventListener("keyup", this.onKeyUp, false);
            }
        }
    }

    componentDidMount()
    {
        const { enableShortCutKey } = this.props;
        if (enableShortCutKey)
        {
            document.addEventListener("keyup", this.onKeyUp, false);
        }
    }

    componentWillUnmount()
    {
        document.removeEventListener("keyup", this.onKeyUp, false);
    }
      
    render() {
        const { children, ...other } = this.props;
        const { activeKey } = this.state;
        
        return (
            <Tabs {...other} activeKey={activeKey} >
                {children}
            </Tabs>
        );
    }
}

export default TabsEnhanced;